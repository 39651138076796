.creatable-container {
	position: relative;
	font-family: $font;
	@for $i from 1 through 20 {
		&.z-index-#{$i} {
			z-index: $i * 100;
		}
	}
	.creatable__control {
		min-height: 55px !important;
		&.creatable__control--is-focused,
		&:hover {
			border-color: black !important;
			box-shadow: none !important;
		}
	}
	& + .MuiFormControl-root {
		margin-top: 16px;
	}
	.creatable__menu {
		text-align: left;
	}
	&.invalid {
		.creatable__control {
			border-color: $red !important;
		}
	}
}
