$red: #f44336;
$font: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
	"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
	"Segoe UI Symbol";

@import "creatable";

.helper-text {
	color: $red;
	font-size: 0.6428571428571428rem;
	font-family: $font;
	display: block;
	text-align: left;
	margin-left: 14px;
	margin-top: 6px;
}

.hljs {
	border-radius: 5px;
	padding: 25px;
	width: 1000px;
	max-height: 700px;
}
